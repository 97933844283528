<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; max-width: 360px;"
    fluid
  >
    <v-card>
      <v-list>
        <v-list-item
          class="py-1"
        >
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.people') }}
              </span>
            </v-row>

            <v-row
              v-if="value.peopleTags.length === 0"
              class="py-1 align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openPeopleTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>

              <v-switch
                v-model="value.noPeopleTags"
                inset
                hide-details
                class="ma-0 ml-4"
                :label="$t('deconve.noTags')"
                @change="onFilterChanged"
              />
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="tagId in value.peopleTags"
                :key="tagId"
                class="pa-1"
                :tag-id="tagId"
              />

              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openPeopleTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item
          class="py-1"
        >
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.notifications') }}
              </span>
            </v-row>

            <v-row
              v-if="value.notificationTags.length === 0"
              class="py-1 align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openNotificationTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>
              <v-switch
                v-model="value.noNotificationTags"
                inset
                hide-details
                class="ma-0 ml-4"
                :label="$t('deconve.noTags')"
                @change="onFilterChanged"
              />
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="tagId in value.notificationTags"
                :key="tagId"
                class="pa-1"
                :tag-id="tagId"
              />
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openNotificationTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          :disabled="!hasChanged"
          :small="$vuetify.breakpoint.mobile"
          @click="clearFilters"
        >
          {{ $t('deconve.clearFilters') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <tags-manager-dialog ref="tagDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mdiTagPlusOutline } from '@mdi/js';

import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import Tag from '@/components/Tag.vue';

export default {
  name: 'ReportsFilter',
  components: {
    TagsManagerDialog,
    Tag,
  },
  props: {
    value: { type: Object, required: true },
    hasChanged: { type: Boolean, default: false },
  },
  data() {
    return {
      icons: {
        addTag: mdiTagPlusOutline,
      },
    };
  },
  methods: {
    clearFilters() {
      this.$emit('clear');
    },
    openNotificationTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.notificationTags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.notificationTags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    openPeopleTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.peopleTags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.peopleTags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    onFilterChanged() {
      this.$emit('change');
    },
  },
};
</script>
